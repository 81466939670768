import React, { useState } from 'react';
import CooperationPopup from '../CooperationPopup/CooperationPopup';
import "./Hero.css"

const Hero = () => {
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <section className='hero-container'>
      <div className='hero-text'>
        <h1>摩态口腔连锁诊所</h1>
        <h3 className='subheading'>国际口腔连锁品牌，扎根社区</h3>
        <h3 className='subheading'>您身边的口腔护理专家</h3>
        <p className='text'>*以上诊所名称仅供演示</p>
      </div>
      <div className='hero-examples'>
        <div className='example'>
          <img src="./wechat.svg" alt="logo" width={40} />
          <h3>接入微信公众号</h3>
        </div>
        <div className='example'>
          <img src="./browser.png" alt="logo" width={40} />
          <h3>接入自有网站</h3>
        </div>
        <div className='example'>
          <img src="./facebook.svg" alt="logo" width={40} />
          <h3>接入Facebook</h3>
        </div>
      </div>
      <button className='hero-button' onClick={togglePopup}>
        合作咨询
      </button>
      {showPopup && <CooperationPopup onClose={togglePopup} />}
    </section>
  );
};

export default Hero
