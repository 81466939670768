import React, { useState, useContext, useEffect,useRef } from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import './Chat.css';
import { AuthContext } from '../AuthContext';

// Define the getAnswer function to fetch answers from the API
async function getAnswer(question, domain) {
  const response = await fetch('https://modol.live/answer/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ question, domain }),
  });

  const data = await response.json();
  return data.answer;
}

const Chat = ({  remainingQuota, updateRemainingQuota }) => {
  const [messages, setMessages] = useState([
    {
      message: '你好，我是摩态牙齿护理助手！',
      sender: 'ModolAI',
    },
  ]);

  // Create a ref to the last message element
  const lastMessageRef = useRef(null);

  // Function to scroll to the last message
  const scrollToLastMessage = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [isTyping, setIsTyping] = useState(false);
  const [assistantMode, setAssistantMode] = useState('牙齿护理助手'); // Default mode
  const [quotaExhausted, setQuotaExhausted] = useState(false);

  // Access the isLoggedIn state and authentication functions from AuthContext
  const { isLoggedIn } = useContext(AuthContext);

  // Function to record chat usage after each successful interaction
  const recordChatUsage = async (modelName, tokenNum, content) => {
    // Include the access token from localStorage in the request headers
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch('https://api.aws.modol.vip:18080/api/v1/chat/chat_record', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          model_name: modelName,
          token_num: tokenNum,
          content,
        }),
      });

      if (!response.ok) {
        console.error('Failed to record chat usage:', response.statusText);
      }
    } catch (error) {
      console.error('Error recording chat usage:', error);
    }
  };

  const handleSend = async (message) => {
    const newMessage = {
      message,
      sender: 'user',
      direction: 'outgoing',
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    setIsTyping(true);

    try {
      if (quotaExhausted) {
        // Send a message indicating the quota is exhausted
        const quotaExhaustedMessage = {
          message: '免费额度已用完，请使用合作表单联系我们获取更多额度',
          sender: 'ModolAI',
          direction: 'incoming',
        };

        newMessages.push(quotaExhaustedMessage);
        setMessages(newMessages);
        return; // Return early and do not make an API request
      }


      if (isLoggedIn) {
        let domain = 'medical'; // Default domain
        if (assistantMode === '牙齿护理助手') {
          domain = 'dental';
        } else if (assistantMode === '宠物护理助手') {
          domain = 'pets';
        }

        const response = await getAnswer(message, domain);

        // Create a new message for the AI's response
        const aiResponseMessage = {
          message: response,
          sender: 'Modol',
          direction: 'incoming',
        };

        // Update the state with the AI's response
        newMessages.push(aiResponseMessage);
        setMessages(newMessages);

        // Record chat usage after a successful interaction
        recordChatUsage(assistantMode, 1, message); // Modify tokenNum if needed
        updateRemainingQuota();
        if (remainingQuota <= 0) {
          setQuotaExhausted(false);
          return;
        }
      } else {
        // Send a message indicating that the user should log in first
        const loginMessage = {
          message: '请登录后再发送信息',
          sender: 'ModolAI',
          direction: 'incoming',
        };
        newMessages.push(loginMessage);
        setMessages(newMessages);
      }
    } catch (error) {
      console.error('Error getting answer:', error);
    } finally {
      // Clear the typing indicator
      setIsTyping(false);
      scrollToLastMessage();
    }
  };

  // Function to change the assistant mode and welcome message
  const changeAssistantMode = (newMode, welcomeMessage) => {
    setAssistantMode(newMode);
    setMessages([
      {
        message: `你好，我是 ${welcomeMessage}！`,
        sender: 'ModolAI',
      },
    ]);
  };

  return (
    <div className="chatui">
      <div className="button-container">
        {/* <button
          className="api-button med"
          onClick={() => changeAssistantMode('医学科普助手', '摩态医学科普助手')}
        >
          医学科普
        </button> */}
        <button
          className="api-button dental"
          onClick={() => changeAssistantMode('牙齿护理助手', '摩态牙齿护理助手')}
        >
          摩态牙齿护理助手
        </button>
        {/* <button
          className="api-button pet"
          onClick={() => changeAssistantMode('宠物护理助手', '摩态宠物护理助手')}
        >
          宠物护理
        </button> */}
        {/* Add buttons for other modes */}
      </div>
      <div style={{ position: 'relative', height: '400px' }} className='chatComponent'>
        <MainContainer>
          <ChatContainer>
            <MessageList
              scrollBehavior="auto"
              typingIndicator={
                isTyping ? (
                  <TypingIndicator content={`${assistantMode} 正在输入中`} />
                ) : null
              }
            >
              {messages.map((message, i) => {
                const isLastMessage = i === messages.length - 1;
                return (
                  <Message
                    key={i}
                    model={message}
                    ref={isLastMessage ? lastMessageRef : null} // Set a ref on the last message
                  />
                );
              })}
            </MessageList>
            <MessageInput placeholder="在此输入你的问题" onSend={handleSend} />
          </ChatContainer>
        </MainContainer>
      </div>
    </div>
  );
};

export default Chat;

