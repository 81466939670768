import React, { createContext, useState } from "react";

// Create an AuthContext
export const AuthContext = createContext();

// Create an AuthProvider component
export const AuthProvider = ({ children, updateQuota }) => {
  // State to track whether the user is logged in or no
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  // Function to handle user login
  const handleLogin = (user) => {
    setIsLoggedIn(true);
    // Optionally, you can set user data if needed
    // setUserData(user);
    updateQuota();
  };

  // Function to handle user logout
  const handleLogout = () => {
    setIsLoggedIn(false);
    // Optionally, you can clear user data if needed
    // setUserData({});
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, onLogin: handleLogin, onLogout: handleLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
